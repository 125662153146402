import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Features from './section-components/features-v1';
import Team from './section-components/team-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';


const Careers = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return <div>
        <Navbar />
        <PageHeader headertitle="Careers" />
        {/* <AboutV4 />
        <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Team />
        <Testimonial />
        <BlogSlider />
        <CallToActionV1 /> */}
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow" style={{maxWidth: "500px", minWidth: "500px"}}>
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" />
						</div>
						<h3>Email Address</h3>
						<p>careers@atom-power.com <br />
						</p>
					</div>
                    </div>
        <Footer />
    </div>
}

export default Careers;

