import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Construction } from 'lucide-react';

const Shop_V1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Products" />
        {/* <ShogGrid />
        <CallToActionV1 /> */}
        <div style={{display: "flex", margin: "0.5rem", flexDirection: "column", justifyContent: "center", alignItems: "center", gap:"0.25rem"}}>
            <div>
                <Construction className='my-icon' />
            </div>
            <div style={{padding: "1rem", marginBottom: "2rem"}}>
                <span style={{textAlign: "center", fontSize: "26px", fontWeight: "bold"}}>We're making improvements! Our website is currently under maintenance. Please check back soon.</span>
            </div>
        </div>
        <Footer />
    </div>
}

export default Shop_V1

