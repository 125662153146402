import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CategoryV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color" style={{ fontSize: "18px" }}>
                  Smart, Safe, Powerful Performance
                </h6>
                <h1 className="section-title">Key Features</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-car" />
                  </span>
                  <span className="category-number">01</span>
                  <span className="category-title">Long Cycle Life</span>
                  <span className="category-brief">
                    Enjoy long-lasting power with a our ATOM Power battery
                    designed for an extended cycle life
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="#"
                >
                  <span className="category-icon">
                    <i className="flaticon-swimming" />
                  </span>
                  <span className="category-number">02</span>
                  <span className="category-title">BMS</span>
                  <span className="category-brief">
                    We are dedicated to sustainability with eco-friendly
                    batteries that lower your carbon footprint.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-secure-shield" />
                  </span>
                  <span className="category-number">03</span>
                  <span className="category-title">
                    Fast Charging/  Discharging
                  </span>
                  <span className="category-brief">
                    ATOM Power delivers fast charging and discharging for energy
                    when you need it.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-stethoscope" />
                  </span>
                  <span className="category-number">04</span>
                  <span className="category-title">
                    Environmentally Friendly
                  </span>
                  <span className="category-brief">
                    ATOM Power offers eco-friendly batteries that reduce your
                    carbon footprint.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-book" />
                  </span>
                  <span className="category-number">05</span>
                  <span className="category-title">Remote Monitoring</span>
                  <span className="category-brief">
                    ATOM Power allows remote monitoring for full control and
                    insights.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-bed-1" />
                  </span>
                  <span className="category-number">06</span>
                  <span className="category-title">Quiet Operation</span>
                  <span className="category-brief">
                    ATOM Power ensures quiet operation, providing seamless
                    energy without the noise.
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <div
                // to="/shop"
                >
                  <span className="category-icon">
                    <i className="flaticon-home-2" />
                  </span>
                  <span className="category-number">07</span>
                  <span className="category-title">WiFi Enabled</span>
                  <span className="category-brief">
                    ATOM Power’s WiFi-enabled batteries offer easy connectivity
                    and remote control for convenience
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <Link to="/shop">
                  <span className="category-icon">
                    <i className="flaticon-slider" />
                  </span>
                  <span className="category-number">08</span>
                  <span className="category-title">Kid’s Playland</span>
                  <span className="category-brief">
                    Enimad minim veniam quis no exercitation ullamco lab
                  </span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryV2;
