import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

class BlogSlider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let customClass = this.props.customClass ? this.props.customClass : "";
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : "";
    return (
      <div className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6
                  className={
                    "section-subtitle ltn__secondary-color " + customClass
                  }
                >
                  News &amp; Blogs
                </h6>
                <h1 className="section-title">Latest News Feeds</h1>
              </div>
            </div>
          </div>
          <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
             {/* Blog Item */}
             <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                <a
  href="https://propakistani.pk/?p=837483&preview=1&_ppp=b11776af1e&v9"
  target="_blank"
  rel="noopener noreferrer" // This is recommended for security
>
  <img
    src={publicUrl + "assets/img/blog/news-3.png"}
    alt="#"
  />
</a>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <div
                        // to="/team-details"
                        >
                          <i className="far fa-user" />
                          by: ProPakistani
                        </div>
                      </li>
                      <li className="ltn__blog-tags">
                        <div
                        // to="/blog-grid"
                        >
                          <i className="fas fa-tags" />
                          lithuimbattery
                        </div>
                        {/* <div to="/blog-grid">
                          <i className="fas fa-tags" />
                          Estate
                        </div> */}
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <div
                    // to="/blog-details"
                    >
                      Atom Power Launches Pakistan’s First Locally Assembled
                       Batteries
                    </div>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          December 03, 2024
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      {/* <div
                      //   to="/blog-details"
                      >
                        Read more
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <a
                  target="_blank"
  rel="noopener noreferrer"
                    href="https://propakistani.pk/2024/10/31/elfa-paves-the-way-for-locally-engineered-transportation-in-pakistan/amp/
" 
                  >
                    <img
                      src={publicUrl + "assets/img/blog/news-1.png"}
                      //   alt="#"
                    />
                  </a>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <div
                        // to="/team-details"
                        >
                          <i className="far fa-user" />
                          by: ProPakistani
                        </div>
                      </li>
                      <li className="ltn__blog-tags">
                        {/* <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Ev
                        </Link> */}
                        <div
                        // to="/blog-grid"
                        >
                          <i className="fas fa-tags" />
                          electricbike
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <div
                    // to="/blog-details"
                    >
                      ELFA Paves the Way for Locally-Engineered
                      Transportation in Pakistan
                    </div>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          September 12, 2024
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      {/* <div
                      //   to="/blog-details"
                      >
                        Read more
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                <a
                  target="_blank"
  rel="noopener noreferrer"
                    href="https://www.brecorder.com/news/40322000"

                  >
                    <img
                      src={publicUrl + "assets/img/blog/news-2.png"}
                      alt="#"
                    />
                  </a>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <div
                        // to="/team-details"
                        >
                          <i className="far fa-user" />
                          by: BusinessRecorder
                        </div>
                      </li>
                      <li className="ltn__blog-tags">
                        <div
                        // to="/blog-grid"
                        >
                          <i className="fas fa-tags" />
                          ncrwavetec
                        </div>
                        {/* <Link to="/blog-grid">
                          <i className="fas fa-tags" />X
                        </Link> */}
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <div
                    // to="/blog-details"
                    >
                      BML partners with NCR, Wavetec to elevate
                      self-banking network
                    </div>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          October 31, 2024
                        </li>
                      </ul>
                    </div>
                    {/* <div className="ltn__blog-btn">
                      <Link
                      //   to="/blog-details"
                      >
                        Read more
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
           
            {/* Blog Item */}
            {/* <div className="col-lg-12">
		        <div className="ltn__blog-item ltn__blog-item-3">
		          <div className="ltn__blog-img">
		            <Link to="/blog-details"><img src={publicUrl+"assets/img/blog/4.jpg"} alt="#" /></Link>
		          </div>
		          <div className="ltn__blog-brief">
		            <div className="ltn__blog-meta">
		              <ul>
		                <li className="ltn__blog-author">
		                  <Link to="/team-details"><i className="far fa-user" />by: Admin</Link>
		                </li>
		                <li className="ltn__blog-tags">
		                  <Link to="/blog-grid"><i className="fas fa-tags" />Interior</Link>
		                  <Link to="/blog-grid"><i className="fas fa-tags" />Room</Link>
		                </li>
		              </ul>
		            </div>
		            <h3 className="ltn__blog-title"><Link to="/blog-details">Renovating a Living Room? Experts Share Their Secrets</Link></h3>
		            <div className="ltn__blog-meta-btn">
		              <div className="ltn__blog-meta">
		                <ul>
		                  <li className="ltn__blog-date"><i className="far fa-calendar-alt" />June 24, 2021</li>
		                </ul>
		              </div>
		              <div className="ltn__blog-btn">
		                <Link to="/blog-details">Read more</Link>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div> */}
            {/* Blog Item */}
            {/* <div className="col-lg-12">
		        <div className="ltn__blog-item ltn__blog-item-3">
		          <div className="ltn__blog-img">
		            <Link to="/blog-details"><img src={publicUrl+"assets/img/blog/5.jpg"} alt="#" /></Link>
		          </div>
		          <div className="ltn__blog-brief">
		            <div className="ltn__blog-meta">
		              <ul>
		                <li className="ltn__blog-author">
		                  <Link to="/team-details"><i className="far fa-user" />by: Admin</Link>
		                </li>
		                <li className="ltn__blog-tags">
		                  <Link to="/blog-grid"><i className="fas fa-tags" />Interior</Link>
		                  <Link to="/blog-grid"><i className="fas fa-tags" />Trends</Link>
		                </li>
		              </ul>
		            </div>
		            <h3 className="ltn__blog-title"><Link to="/blog-details">7 home trends that will shape your house in 2021</Link></h3>
		            <div className="ltn__blog-meta-btn">
		              <div className="ltn__blog-meta">
		                <ul>
		                  <li className="ltn__blog-date"><i className="far fa-calendar-alt" />June 24, 2021</li>
		                </ul>
		              </div>
		              <div className="ltn__blog-btn">
		                <Link to="/blog-details">Read more</Link>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div> */}
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSlider;
