import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img
                        src={publicUrl + "assets/img/logo-white.png"}
                        alt="Logo"
                      />
                    </div>
                  </div>
                  <p>
                    Atom Power (PRIVATE) Limited, based in Karachi, is
                    transforming energy storage with locally assembled
                    lithium-ion batteries. Our new facility, launching by Q2
                    2025, will focus on sustainability and locally sourced
                    materials. As a Wavetec subsidiary, we aim to lead global
                    energy storage with innovation and reliability.
                  </p>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            C3i GA-70-A3, Korangi Creek Industrial Park Korangi,
                            Karachi, Sindh
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+0123-456789">+92-326-8314491 </a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:example@example.com">
                              sales@atom-power.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <Social />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <a
                        href="#about"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById("about").scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                      >
                        About
                      </a>
                        
                      </li>
                      {/* <li>
                        <Link
                        //  to="/blog-grid"
                        >
                          Blog
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/shop"
                        >
                          All Products
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/contact"
                        >
                          Contact
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/faq"
                        >
                          FAQ
                        </Link>
                      </li> */}
                      <li>
                        <Link
                         to="/contact"
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      {/* <li>
                        <Link
                        // to="/cart"
                        >
                          Warranty & Maintenance
                        </Link>
                      </li> */}
                      <li>
                        <Link
                         to="/contact"
                        >
                          Technical Support
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                        // to="/login"
                        >
                          Energy Consulting
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/checkout"
                        >
                          Financing Options
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/about"
                        >
                          Custom Solutions
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/shop"
                        >
                          Product Upgrades
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Products</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      {/* <li>
                        <Link
                        // to="/login"
                        >
                          How It Works
                        </Link>
                      </li>
                      <li>
                        <Link
                        // to="/my-account"
                        >
                          Warranty & Support
                        </Link>
                      </li>
                      <li>
                        <Link
                        // to="/wishlist"
                        >
                          Installation Services
                        </Link>
                      </li>
                      <li>
                        <Link
                        // to="/add-listing"
                        >
                          Media
                        </Link>
                      </li> */}
                      <li>
                        <Link
                         to="/careers"
                        >
                          Careers{" "}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                        // to="/contact"
                        >
                          Privacy Policy
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                        // to="/contact"
                        >
                          Terms & Conditions
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Newsletter</h4>
                  <p>
                    Subscribe to our weekly Newsletter and receive updates via
                    email.
                  </p>
                  <div className="footer-newsletter">
                    <form action="#">
                      <input type="email" name="email" placeholder="Email*" />
                      <div className="btn-wrapper">
                        <button className="theme-btn-1 btn" type="submit">
                          <i className="fas fa-location-arrow" />
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <h5 className="mt-30">We Accept</h5>
                  <img
                    src={publicUrl + "assets/img/icons/payment-4.png"}
                    alt="Payment Image"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
