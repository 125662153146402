import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Availability extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="select-availability-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color" style={{ fontSize: "18px" }}>
                  Key Usability Facts
                </h6>
                <h1 className="section-title">Technical Specifications</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__select-availability-table-wrap">
                <div className="ltn__select-availability-table d-none d-md-block">
                  <ul className="ltn__select-availability-table-head">
                    <li style={{textAlign: "left", fontWeight: "bold"}}>Main Parameters </li>
                    <li>-</li>
                    <li style={{textAlign: "left", fontWeight: "bold"}}>Environment</li>
                    <li>- </li>
                    <li style={{textAlign: "left", fontWeight: "bold"}}>Mechanical</li>
                    <li>-</li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Battery Type</li>
                    <li style={{textAlign: "left"}}>LiFePO4</li>
                    <li style={{textAlign: "left"}}>Protection Rating</li>
                    <li style={{textAlign: "left"}}>IP21</li>
                    <li style={{textAlign: "left"}}>Weight</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        50kg
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Capacity</li>
                    <li style={{textAlign: "left"}}>100 AH</li>
                    <li style={{textAlign: "left"}}>Altitude Limit</li>
                    <li style={{textAlign: "left"}}> 2000 m</li>
                    <li style={{textAlign: "left"}}>Size</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        580 x 520 x 190 mm
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Voltage</li>
                    <li style={{textAlign: "left"}}>51.2 volts</li>
                    <li style={{textAlign: "left"}}>Storage Temperature</li>
                    <li style={{textAlign: "left"}}>0°C to 40°C </li>
                    <li style={{textAlign: "left"}}>Installation</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        Wall Mounted and Table Top
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Operating Voltage Range</li>
                    <li style={{textAlign: "left"}}>43.2V to 57.6V</li>
                    <li style={{textAlign: "left"}}>Humidity Tolerance</li>
                    <li style={{textAlign: "left"}}>5% to 95% </li>
                    <li style={{textAlign: "left"}}>Recommended</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        50 Amps
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Energy Storage</li>
                    <li style={{textAlign: "left"}}>5.12 kWh</li>
                    <li style={{textAlign: "left"}}>Operating Temperature</li>
                    <li style={{textAlign: "left"}}>- 10°C to 50°C </li>
                    <li style={{textAlign: "left"}}>Maximum</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        100 Amps
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Usable Energy</li>
                    <li style={{textAlign: "left"}}>4.6 kWh</li>
                    <li style={{textAlign: "left"}}>Connectivity</li>
                    <li style={{textAlign: "left"}}>Wi-Fi (for cloud) CAN & RS485 (for inverter) </li>
                    <li style={{textAlign: "left"}}>Recommended Battery Usages</li>
                    <li style={{textAlign: "left"}}>
                      <a
                      //   href="product-details.html"
                      >
                        Discharge Up to 90%
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Display</li>
                    <li style={{textAlign: "left"}}>LCD Screen</li>
                    <li style={{textAlign: "left"}}>Warranty</li>
                    <li style={{textAlign: "left"}}>5 Years </li>
                    <li>-</li>
                    <li>
                      <a
                      //   href="product-details.html"
                      >
                        -
                      </a>
                    </li>
                  </ul>
                  <ul className="ltn__select-availability-table-row">
                    <li className="tower-name" style={{textAlign: "left"}}>Battery Life</li>
                    <li style={{textAlign: "left"}}>6000 @ 0.5C (Temp 25°C, EOL 70%)</li>
                    <li style={{textAlign: "left"}}>Safety Standards</li>
                    <li style={{textAlign: "left"}}>UN38.3 Certified</li>
                    <li>-</li>
                    <li>
                      <a
                      //   href="product-details.html"
                      >
                        -
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ltn__select-availability-table-responsive d-md-none" >
                  <ul className="ltn__select-availability-table-row-responsive-item" >
                    <li style={{textAlign: "left", fontWeight: "bold"}}>
                      <span>Main Parameters</span>
                      <span className="tower-name">-</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Battery Type</span> <span>LiFePO4</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Capacity</span> <span>100 AH</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Voltage</span> <span>51.2 volts</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Operating Voltage Range </span> <span>43.2V to 57.6V</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Energy Storage </span> <span>5.12 kWh</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Usable Energy </span> <span>4.6 kWh</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Display </span> <span>LCD Screen</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Battery Life </span> <span>6000 @ 0.5C (Temp 25°C, EOL 70%)</span>
                    </li>
                    {/* <li>
                      <span>Details</span>{" "}
                      <span>
                        <a
                        // href="product-details.html"
                        >
                          + Available
                        </a>
                      </span>
                    </li> */}
                  </ul>
                  <ul className="ltn__select-availability-table-row-responsive-item">
                    <li style={{textAlign: "left", fontWeight: "bold"}}>
                      <span>Mechanical</span>{" "}
                      <span className="tower-name">-</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Weight</span> <span>50kg</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Size</span> <span>580 x 520 x 190 mm</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Installation</span> <span>Wall Mounted and Table Top</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Recommended</span> <span>50 Amps
                      </span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Maximum</span> <span>100 Amps
                      </span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Recommended Battery Usages
                      </span> <span>Discharge Up to 90%
                      </span>
                    </li>
                    {/* <li style={{textAlign: "left"}}>
                      <span>Details</span>{" "}
                      <span>
                        <a
                        // href="product-details.html"
                        >
                          + Available
                        </a>
                      </span>
                    </li> */}
                  </ul>
                  <ul className="ltn__select-availability-table-row-responsive-item">
                    <li style={{textAlign: "left", fontWeight: "bold"}}>
                      <span>Environment</span>{" "}
                      <span className="tower-name">-</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Protection Rating</span> <span>IP21
                      </span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Altitude Limit</span> <span>2000 m</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Storage Temperature</span> <span>0°C to 40°C</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Humidity Tolerance
                      </span> <span>5% to 95%</span>
                    </li>
                    <li style={{textAlign: "left"}}>
                      <span>Operating Temperature</span> <span>- 10°C to 50°C</span>
                    </li><li style={{textAlign: "left"}}>
                      <span>Connectivity</span> <span>Wi-Fi (for cloud) CAN & RS485 (for inverter)</span>
                    </li><li style={{textAlign: "left"}}>
                      <span>Warranty</span> <span>5 Years</span>
                    </li><li style={{textAlign: "left"}}>
                      <span>Safety Standards
                      </span> <span>UN38.3 Certified
                      </span>
                    </li>
                    {/* <li>
                      <span>Details</span>{" "}
                      <span>
                        <a
                        // href="product-details.html"
                        >
                          + Available
                        </a>
                      </span>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Availability;
