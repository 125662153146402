import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ApartmentV2 extends Component {
  render() {
    let CustomClass = this.props.customClass ? this.props.customClass : "";

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}
        id="about"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                {/* <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Apartment Sketch
                </h6> */}
                {/* <h1 className="section-title">About Us</h1> */}
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  {/* <a data-bs-toggle="tab" href="#liton_tab_3_1">
                    The Studio
                  </a> */}
                  {/* <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_2"
                  >
                    About Us
                  </a> */}
                  {/* <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    Penthouse
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_4">
                    Top Garden
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_5">
                    Double Height
                  </a> */}
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade" id="liton_tab_3_1">
                  <div className="ltn__apartments-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>The Studio</h2>
                          <p>
                            Enimad minim veniam quis nostrud exercitation
                            ullamco laboris. Lorem ipsum dolor sit amet cons
                            aetetur adipisicing elit sedo eiusmod
                            tempor.Incididunt labore et dolore magna aliqua. sed
                            ayd minim veniam.
                          </p>
                          <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Total Area</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade active show" id="liton_tab_3_2">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>About Us</h2>
                          <p>
                            Atom Power (PRIVATE) Limited is transforming the energy storage
                            landscape with locally assembled lithium-ion batteries. Based 
                            in Pakistan (Karachi), we’re set to launch a state-of-the-art 
                            manufacturing facility by Q2 2025. In Phase-2, we will enhance
                            sustainability by using locally sourced materials.
                          </p>
                          <p>
                            Aiming to serve both local and international markets, Atom Power 
                            is poised to become a global leader in energy storage. 
                          </p>
                          <p>  
                            As a subsidiary of Wavetec, a tech industry pioneer with <strong>
                            over 30 years</strong> of experience, we combine innovation with 
                            reliability to drive growth and sustainability worldwide.
                          </p>
                          {/* <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Total Area</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/about.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/mobile-app.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>App and Web Portal for End Users</h2>
                          <p>
                            With ATOM Power, take control of your energy like
                            never before. Our intuitive power flow monitoring
                            system provides real-time insights into your energy
                            usage, helping you optimize performance and reduce
                            costs. Say goodbye to high energy bills with our
                            advanced Self-Powered Mode, designed to prioritize
                            stored solar energy for your home or business.
                            Easily track power generation and consumption
                            through our user-friendly mobile app, giving you
                            complete transparency at your fingertips.
                            Additionally, ATOM Power offers comprehensive and
                            detailed data analytics to assist in identifying and
                            resolving system faults, ensuring reliable and
                            efficient energy storage for your needs.
                          </p>

                          {/* <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Intuitive power flow monitoring</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_3">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>Penthouse</h2>
                          <p>
                            Enimad minim veniam quis nostrud exercitation
                            ullamco laboris. Lorem ipsum dolor sit amet cons
                            aetetur adipisicing elit sedo eiusmod
                            tempor.Incididunt labore et dolore magna aliqua. sed
                            ayd minim veniam.
                          </p>
                          <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Total Area</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_4">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>Top Garden</h2>
                          <p>
                            Enimad minim veniam quis nostrud exercitation
                            ullamco laboris. Lorem ipsum dolor sit amet cons
                            aetetur adipisicing elit sedo eiusmod
                            tempor.Incididunt labore et dolore magna aliqua. sed
                            ayd minim veniam.
                          </p>
                          <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Total Area</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_5">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
                          <h2>Double Height</h2>
                          <p>
                            Enimad minim veniam quis nostrud exercitation
                            ullamco laboris. Lorem ipsum dolor sit amet cons
                            aetetur adipisicing elit sedo eiusmod
                            tempor.Incididunt labore et dolore magna aliqua. sed
                            ayd minim veniam.
                          </p>
                          <div className="apartments-info-list apartments-info-list-color mt-40">
                            <ul>
                              <li>
                                <label>Total Area</label>{" "}
                                <span>2800 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bedroom</label> <span>150 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Bathroom</label> <span>45 Sq. Ft</span>
                              </li>
                              <li>
                                <label>Belcony/Pets</label> <span>Allowed</span>
                              </li>
                              <li>
                                <label>Lounge</label> <span>650 Sq. Ft</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/10.png"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApartmentV2;
