import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProductSliderV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color" style={{ fontSize: "18px" }}>
                  We put our customers first
                </h6>
                <h1 className="section-title">
                  Our Focus Area
                  <br />
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
            {/* Safety and Convenience */}
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <div
                  //   to="/product-details"
                  >
                    <img
                      src={publicUrl + "assets/img/product-3/focus-3.png"}
                      alt="#"
                    />
                  </div>
                  <div className="search-by-place-badge">
                    <ul>
                      <li>Reliable</li>
                    </ul>
                  </div>
                </div>
                <div className="search-by-place-info">
                  <h4>
                    <div
                    // to="/product-details"
                    >
                      Safety & Convenience
                    </div>
                  </h4>
                  <p>
                    Prioritize user safety with robust protection features and
                    provide easy installation, maintenance, and compatibility
                    with existing systems.
                  </p>
                  {/* <h6>
                    <Link to="/contact">Sedona, Arizona</Link>
                  </h6>
                  <h4>
                    <Link to="/product-details">Noe Valley Zones</Link>
                  </h4>
                  <div className="search-by-place-btn">
                    <Link to="/product-details">
                      View Property <i className="flaticon-right-arrow" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Performance and Reliability */}
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <div
                  //   to="/product-details"
                  >
                    <img
                      src={publicUrl + "assets/img/product-3/focus-1.png"}
                      alt="#"
                    />
                  </div>
                  <div className="search-by-place-badge">
                    <ul>
                      <li>Powerwall</li>
                    </ul>
                  </div>
                </div>
                <div className="search-by-place-info">
                  <h4>
                    <div
                    // to="/product-details"
                    >
                      Performance & Reliability
                    </div>
                  </h4>
                  <p>
                    Ensure high-capacity, long-lasting batteries with consistent
                    performance for uninterrupted power at home (Powerwall) and
                    extended range for e-bikes.
                  </p>
                  {/* <h6>
                    <Link to="/contact">San Francisco</Link>
                  </h6>
                  <div className="search-by-place-btn">
                    <Link to="/product-details">
                      View Property <i className="flaticon-right-arrow" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Sustainability and Innovation */}
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <div
                  //   to="/product-details"
                  >
                    <img
                      src={publicUrl + "assets/img/product-3/focus-2.png"}
                      alt="#"
                    />
                  </div>
                  <div className="search-by-place-badge">
                    <ul>
                      <li>Sustainable</li>
                    </ul>
                  </div>
                </div>
                <div className="search-by-place-info">
                  <h4>
                    <div
                    // to="/product-details"
                    >
                      Sustainability & Innovation
                    </div>
                  </h4>
                  <p>
                    Focus on eco-friendly designs with advanced technologies,
                    offering energy-efficient and sustainable solutions for
                    modern living and commuting.
                  </p>
                  {/* <h6>
                    <Link to="/contact">New York</Link>
                  </h6>
                  <h4>
                    <Link to="/product-details">Pacific Heights Area</Link>
                  </h4>
                  <div className="search-by-place-btn">
                    <Link to="/product-details">
                      View Property <i className="flaticon-right-arrow" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSliderV2;
